.subscription-page {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
  padding: 2%;
  box-sizing: border-box;
}

.card {
  /* background-color: #ffffff; */
  background-color: #e6f2ff;  /* azul suave */
  border-radius: 15px;
  padding: 2rem;
  width: calc(33% - 20px);  
  min-height: 400px;  
  margin: 0 auto;
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .subscription-page {
    flex-direction: column;
  }

  .card {
    width: 100%;
    min-height: 200px;
  }
}
  
  .card-title {
    font-size: 25px;
    text-align: center;
  }
  
  .card-description {
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .card-price {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .card-benefits {
    list-style: none;
    padding: 0;
  }
  .card-benefits li {
    text-align: justify;
  }
  
  .card-link {
    display: inline-block;
    margin-top: 10px;
    color: #534be2;
    text-decoration: none;
  }
  
  .card-link:hover {
    color: #635bff;
  }
  
  .included {
    color: #000;  /* o el color que quieras para los elementos incluidos */
  }
  
  .not-included {
    color: #888;  /* o el color que quieras para los elementos no incluidos */
  }
  