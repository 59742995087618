.container {
    margin-top: 60px; /* Ajusta este valor según la altura de tu navbar */
    padding: 1rem;
    max-width: 900px;

    

  }
  
  .subscription-card {
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 2rem;
    width: 100%;
    max-width: 400px;
  }
  
  .subscription-details {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 80%;

  }
  
  @media (max-width: 480px) {
    .subscription-container {
      margin-top: 80px; /* Ajusta este valor según la altura de tu navbar en dispositivos móviles */
    }
  
    .subscription-card {
      padding: 1rem;
    }
  }
  

  .stripe-card-element {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
  }