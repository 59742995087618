

.chat-outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 40px);
  background-color: #f9f8f8;
 
  
  
}

.chat-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 900px;
  min-width: 300px;
  height: calc(100% - 20px); 
  padding: 15px;
  overflow: hidden; 
  align-items: center;
  justify-content: space-between;

}

.chat-box {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F6FBEE;
  border-radius: 5px;
 
  scrollbar-color: #888 #f1f1f1; /* thumb-color track-color */
}

.message {
  padding: 10px;
  margin-bottom: 10px;
  max-width: 80%;
  word-wrap: break-word;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 5px;
}

.user {
  background-color: #f1f1f1;
  align-self: flex-end;
}

.ai {
  background-color: #72CC5C;
  color: white;
  align-self: flex-start;
}

.chat-input {
  display: flex;
  width: 100%;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Añade esta línea */
  
}

.chat-input input {
  width: 98%;
  flex-grow: 1;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 12px 16px;
  font-size: 1rem;
  transition: 0.2s;
  margin-right: 10px;
  position: relative;
}

.chat-input-span {
  position: relative;
  flex-grow: 1;
}
.chat-input input:focus {
  border-color: #007bff;
  outline: none;
}

.chat-input button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 12px 16px;
  font-size: 1rem;
  cursor: pointer;
  transition: 0.2s;
}

.chat-input button:hover {
  background-color: #0056b3;
}

@media (max-width: 600px) {
  .App {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    min-width: unset;
    border-right: none;
    border-bottom: 1px solid #e0e0e0;
  }

  .chat-container {
    width: 100%;
    height: calc(100vh - 20%);
  }
}


/* Estilo para todas las barras de desplazamiento dentro de la aplicación */
::-webkit-scrollbar {
  width: 8px; /* Ancho de la barra de desplazamiento */
  height: 8px; /* Altura de la barra de desplazamiento horizontal (si es necesario) */
}

/* Estilo del track (fondo) de la barra de desplazamiento */
::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color de fondo del track */
  border-radius: 5px; /* Borde redondeado del track */
}

/* Estilo del thumb (barra deslizante) de la barra de desplazamiento */
::-webkit-scrollbar-thumb {
  background: #888; /* Color de fondo del thumb */
  border-radius: 5px; /* Borde redondeado del thumb */
}

/* Estilo del thumb (barra deslizante) de la barra de desplazamiento al pasar el mouse */
::-webkit-scrollbar-thumb:hover {
  background: #555; /* Color de fondo del thumb al pasar el mouse */
}

.chat-box::-webkit-scrollbar {
  width: 6px;
}

.chat-box::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.chat-box::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.chat-box::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
