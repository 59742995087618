
  
.cardModern {
    width: 100%;
    /* height: 500px; Cambiado para que se adapte al contenido */
    min-height: 500px;
    color: white;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr; /* Tres tarjetas por fila */
    gap: 5px;
    overflow: visible; 
    box-sizing: border-box;
}

.body-card {
    grid-area: body;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden; /* Para evitar el desbordamiento de contenido */
    padding: 20px;
}

.footer-card {
    grid-area: footer;
    border-top: 1px solid rgba(255, 255, 255, 0.1); /* Una línea divisoria opcional */
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-card .buttonCard:not(:last-child) {
    margin-right: 10px;  /* Añade un margen a la derecha de cada botón excepto el último */
}
.footer-card .buttonCard2:not(:last-child) {
    margin-right: 10px;  /* Añade un margen a la derecha de cada botón excepto el último */
}

.footer-card .buttonCard3:not(:last-child) {
    margin-right: 10px;  /* Añade un margen a la derecha de cada botón excepto el último */
}
   
   .cardModern .item {
    border-radius: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
   

   }

   .cardModern .item * {
    margin: 0;
    max-width: 100%;
    box-sizing: border-box;
}
   
   .item:hover {
    transform: scale(0.95);
    transition: all 0.3s;
   }
   
   .item svg {
    width: 40px;
    height: 40px;
    margin-bottom: 7px;
   }

   .item.item--1{
    background-color: #DAE2DF!important;
   }

   .item.item--2{
    background-color: #FEE0CD!important;
   }

   .item.item--3{
    background-color: #EAD9EA!important;
   }
   
  
   
   .text {
    font-size: 28px;
    font-family: inherit;
    font-weight: 530;
   }
   
   .text--1 {
    font-size: 20px;
    color: rgb(45, 45, 45);
    font-family: 'Arial', sans-serif; 
   }
   
   .text--2 {
    color: rgba(252,161,71,1);
   }
   
   .text--3 {
    color: rgba(66,193,110,1);
   }
   
   .text--4 {
    color: rgba(220,91,183,1);
   }
   
   .title--1{
    color: #18a46f;
   }

   .title--2{
    color: #f55700;
   
   }

   .title--3{
    color:#aa1994;
   }

   .buttonCard {
    border: 2px solid #f55700;
    background-color: #f55700;
    border-radius: 0.9em;
    padding: 0.5em 0.8em 0.5em 1em;
    transition: all ease-in-out 0.2s;
    font-size: 16px;
   }
   
   .buttonCard span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 700;
   }
   
   .buttonCard:hover {
    background-color: #DE360B;
   }

   .buttonCard2 {
    border: 2px solid #18A46F;
    background-color: #18A46F;
    border-radius: 0.9em;
    padding: 0.5em 0.8em 0.5em 1em;
    transition: all ease-in-out 0.2s;
    font-size: 16px;
   }
   
   .buttonCard2 span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 700;
   }
   
   .buttonCard2:hover {
    background-color: #11704C;
   }

   .buttonCard3 {
    border: 2px solid #aa1994;
    background-color: #aa1994;
    border-radius: 0.9em;
    padding: 0.5em 0.8em 0.5em 1em;
    transition: all ease-in-out 0.2s;
    font-size: 16px;
   }
   
   .buttonCard3 span {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 700;
   }
   
   .buttonCard3:hover {
    background-color: #781269;
   }

/* Media query for mobile devices */
@media (max-width: 768px) {
    .cardModern {
        grid-template-columns: 1fr; 
        gap: 10px;
        margin: 5px; 
    }
}

@media (max-width: 576px) {
    .cardModern {
        grid-template-columns: 1fr; /* Una tarjeta por fila en dispositivos móviles */
    }

    .cardModern .item {
        padding: 5px;
    }
    
    /* Reducir el tamaño del texto para dispositivos móviles */
    .cardModern .text {
        font-size: 16px; /* Ajusta según tu necesidad */
    }

    .cardModern .title--1,
    .cardModern .title--2,
    .cardModern .title--3{
        font-size: 25px;
    }
    /* Reducir el tamaño de los botones para dispositivos móviles */
    .cardModern .buttonCard, 
    .cardModern .buttonCard2, 
    .cardModern .buttonCard3 {
        font-size: 14px; /* Ajusta según tu necesidad */
        padding: 5px 10px; /* Ajusta el padding de los botones */
    }
}

/* Media query for larger screens */
@media (min-width: 769px) {
    .cardModern {
        grid-template-columns: 1fr 1fr 1fr; /* Three cards per row on large screens */
        gap: 20px; /* Space between rows of cards */
        margin: 20px 0; /* Space at the top and bottom of the cards */
        padding-left: 20px; /* Padding on the left side */
        padding-right: 20px; /* Padding on the right side */
        
    }
}


.modal-payment-body {
    font-family: 'Roboto', sans-serif;
    background-color: #f1efef; /* White background for maximum readability */
    border-radius: 9px; /* Rounded borders */
    padding: 30px;
    color: #333333; /* Dark text for contrast */
    position: relative;
    
  }
  
  .modal-payment-body::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    border-width: 0 45px 45px 0; /* Adjust the size of the triangle */
    border-style: solid;
    border-color: transparent transparent #6f42c1 transparent; /* Purple triangle */
    z-index: 1;
  }
  .modal-payment-title {
    color: #0056b3; /* A strong blue for the title */
    font-size: 2.0em;
    font-weight: 900;
  }
  
  .modal-payment-content {
    margin: 20px 0;
    font-size: 1.1em;
    
  }
  
  .modal-payment-highlight {
    margin: 20px 0;
    font-size: 1.5em;
    color: #28a745; /* Green color for highlighting prices */
    font-weight: 700;
  }
  
  .modal-payment-small-text {
    font-size: 1.2em;
  }
  
  .modal-payment-footer {
    margin-bottom: 25px;
    font-size: 1.1em;
  }
  
  .btn-payment-primary {
    border-radius: 20px;
    padding: 10px 30px;
    font-size: 1.1em;
  }
  
  .text-center {
    text-align: center;
  }




